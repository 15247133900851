import { type FieldProps, getIn, Field } from 'formik';

import {
  NumberInput as BaseNumberInput,
  type NumberInputProps as BaseNumberInputProps,
} from 'components/form-partials/base';

type NumberInputProps<Value extends number, Values extends object = object> = FieldProps<Value, Values> &
  Omit<BaseNumberInputProps<Value>, 'hasError' | 'error' | 'value'> & { name: string; shouldDisplayError?: boolean };

function fieldToNumberInput<Value extends number, Values extends object = object>({
  disabled,
  field: { onBlur: fieldOnBlur, onChange: fieldOnChange, ...field },
  form: { isSubmitting, touched, errors, setFieldValue },
  defaultValue,
  onBlur,
  onChange,
  shouldDisplayError = true,
  ...props
}: NumberInputProps<Value, Values>): BaseNumberInputProps<Value> {
  const hasError = Boolean(!!getIn(errors, field.name) && !!getIn(touched, field.name));
  const error = getIn(errors, field.name);

  return {
    hasError: shouldDisplayError ? hasError : false,
    error: shouldDisplayError ? error : undefined,
    disabled: disabled ?? isSubmitting,
    onBlur: (e) => {
      if (onBlur) return onBlur(e);

      return fieldOnBlur(e ?? field.name);
    },
    onChange: (args, event) => {
      if (onChange) return onChange(args, event);

      const { value } = args;

      const parsedValue = parseFloat(value);

      const dValue = defaultValue ?? 0;

      return setFieldValue(field.name, Number.isNaN(parsedValue) ? dValue : parsedValue);
    },
    defaultValue,
    ...field,
    ...props,
  };
}

function NumberInput<Value extends number, Values extends object = object>(props: NumberInputProps<Value, Values>) {
  return <BaseNumberInput {...fieldToNumberInput(props)} />;
}

export type FormikNumberInputProps<Value extends number, Values extends object = object> = Omit<
  NumberInputProps<Value, Values>,
  'form' | 'meta' | 'field'
>;

export function FormikNumberInput<Value extends number, Values extends object = object>(
  props: FormikNumberInputProps<Value, Values>,
) {
  return <Field component={NumberInput} {...props} />;
}
