import { Field, getIn, type FieldProps } from 'formik';

import {
  Autocomplete as BaseAutocomplete,
  type AutocompleteProps as BaseAutocompleteProps,
} from 'components/form-partials/base';
import type { Optional } from 'types';

type AutocompleteProps<
  T,
  Parameters extends Optional<object> = undefined,
  Values extends object = object,
  Multiple extends Optional<boolean> = false,
  DisableClearable extends Optional<boolean> = false,
> = FieldProps<T, Values> &
  Omit<BaseAutocompleteProps<T, Parameters, Multiple, DisableClearable>, 'hasError' | 'error' | 'value'> & {
    shouldDisplayError?: boolean;
  };

function fieldToAutocomplete<
  T,
  Parameters extends Optional<object> = undefined,
  Values extends object = object,
  Multiple extends Optional<boolean> = false,
  DisableClearable extends Optional<boolean> = false,
>({
  disabled,
  field,
  form: { errors, isSubmitting, touched, setFieldValue },
  onChange,
  onBlur,
  freeSolo,
  shouldDisplayError = true,
  ...props
}: AutocompleteProps<T, Parameters, Values, Multiple, DisableClearable>): BaseAutocompleteProps<
  T,
  Parameters,
  Multiple,
  DisableClearable
> {
  const hasError = Boolean(!!getIn(errors, field.name) && !!getIn(touched, field.name));
  const error = getIn(errors, field.name);

  // eslint-disable-next-line @typescript-eslint/naming-convention, sonarjs/no-unused-vars
  const { onChange: _onChange, onBlur: _onBlur, multiple: _multiple, ...fieldSubSelection } = field;

  return {
    onBlur: (e) => {
      if (onBlur) return onBlur(e);

      return field.onBlur(e ?? field.name);
    },

    onChange: (event, value, reason, details) => {
      if (onChange) return onChange(event, value, reason, details);

      if (Array.isArray(value)) {
        setFieldValue(
          field.name,
          value.map((o) => o.value),
        );
      } else {
        setFieldValue(field.name, value?.value);
      }
    },

    disabled: disabled ?? isSubmitting,
    loading: isSubmitting,
    hasError: shouldDisplayError ? hasError : false,
    error: shouldDisplayError ? error : undefined,
    ...fieldSubSelection,
    ...props,
  };
}

function Autocomplete<
  T,
  Parameters extends Optional<object> = undefined,
  Values extends object = object,
  Multiple extends Optional<boolean> = false,
  DisableClearable extends Optional<boolean> = false,
>(props: AutocompleteProps<T, Parameters, Values, Multiple, DisableClearable>) {
  return <BaseAutocomplete {...fieldToAutocomplete(props)} />;
}

export type FormikAutocompleteProps<
  T,
  Parameters extends Optional<object> = undefined,
  Values extends object = object,
  Multiple extends Optional<boolean> = false,
  DisableClearable extends Optional<boolean> = false,
> = Omit<AutocompleteProps<T, Parameters, Values, Multiple, DisableClearable>, 'form' | 'meta' | 'field'>;

export function FormikAutocomplete<
  T,
  Parameters extends Optional<object> = undefined,
  Values extends object = object,
  Multiple extends Optional<boolean> = false,
  DisableClearable extends Optional<boolean> = false,
>(props: FormikAutocompleteProps<T, Parameters, Values, Multiple, DisableClearable>) {
  return <Field component={Autocomplete} {...props} />;
}
