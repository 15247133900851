import { Field, getIn, type FieldProps } from 'formik';

import {
  AutocompleteInput as BaseAutocompleteInput,
  type AutocompleteInputProps as BaseAutocompleteInputProps,
} from 'components/form-partials/base';
import type { Optional } from 'types';

type AutocompleteInputProps<
  T extends string,
  Parameters extends Optional<object> = undefined,
  Values extends object = object,
> = FieldProps<T, Values> & Omit<BaseAutocompleteInputProps<T, Parameters>, 'hasError' | 'error' | 'value'>;

function fieldToAutocompleteInput<
  T extends string,
  Parameters extends Optional<object> = undefined,
  Values extends object = object,
>({
  disabled,
  field,
  form: { errors, isSubmitting, touched, setFieldValue },
  onChange,
  onBlur,
  ...props
}: AutocompleteInputProps<T, Parameters, Values>): BaseAutocompleteInputProps<T, Parameters> {
  const hasError = Boolean(!!getIn(errors, field.name) && !!getIn(touched, field.name));
  const error = getIn(errors, field.name);

  // eslint-disable-next-line @typescript-eslint/naming-convention, sonarjs/no-unused-vars
  const { onChange: _onChange, onBlur: _onBlur, multiple: _multiple, ...fieldSubSelection } = field;

  return {
    onBlur: (e) => {
      if (onBlur) return onBlur(e);

      return field.onBlur(e ?? field.name);
    },

    onChange: (event, value, reason, details) => {
      if (onChange) return onChange(event, value, reason, details);

      if (typeof value === 'string') {
        setFieldValue(field.name, value);
      } else {
        setFieldValue(field.name, value.value);
      }
    },
    disabled: disabled ?? isSubmitting,
    loading: isSubmitting,
    hasError,
    error,
    ...fieldSubSelection,
    ...props,
  };
}

function AutocompleteInput<
  T extends string,
  Parameters extends Optional<object> = undefined,
  Values extends object = object,
>(props: AutocompleteInputProps<T, Parameters, Values>) {
  return <BaseAutocompleteInput {...fieldToAutocompleteInput(props)} />;
}

export type FormikAutocompleteInputProps<
  T extends string,
  Parameters extends Optional<object> = undefined,
  Values extends object = object,
> = Omit<AutocompleteInputProps<T, Parameters, Values>, 'form' | 'meta' | 'field'>;

export function FormikAutocompleteInput<
  T extends string,
  Parameters extends Optional<object> = undefined,
  Values extends object = object,
>(props: FormikAutocompleteInputProps<T, Parameters, Values>) {
  return <Field component={AutocompleteInput} {...props} />;
}
