import type { Currency } from 'store/accounts/models';
import type { MaintenanceWindowModel } from 'store/common/models';
import type { ISPOption } from 'store/orders/v1/types';
import type { Nullable, Option, PartialRecord } from 'types';

import type {
  AuthenticationType,
  CountryOption,
  IPVersion,
  NetNutHostnameRegion,
  NetworkType,
  Protocol,
  ProxyPropositionAlias,
  ProxySubnetStatus,
  ProxyType,
} from './types';

// #region Common
export enum ProxyActionModel {
  ACTIVATE = 'ACTIVATE',
  ADMIN_DETAILS = 'ADMIN_DETAILS',
  AUTO_EXTEND = 'AUTO_EXTEND',
  BUY_TRAFFIC = 'BUY_TRAFFIC',
  BUY_THREADS = 'BUY_THREADS',
  BUY_UPLINK_SPEED = 'BUY_UPLINK_SPEED',
  CANCEL = 'CANCEL',
  CHANGE_AUTHENTICATION_METHOD = 'CHANGE_AUTHENTICATION_METHOD',
  CHANGE_HTTPS_PROTOCOL = 'CHANGE_HTTPS_PROTOCOL',
  CHANGE_PORT = 'CHANGE_PORT',
  CHANGE_PROTOCOL = 'CHANGE_PROTOCOL',
  CHANGE_THREADS = 'CHANGE_THREADS',
  CHANGE_UPLINK_SPEED = 'CHANGE_UPLINK_SPEED',
  CHANGE_WHITELIST_IP = 'CHANGE_WHITELIST_IP',
  CREATE_ROUTE = 'CREATE_ROUTE',
  DELETE_ROUTE = 'DELETE_ROUTE',
  DOWNLOAD_CREDENTIALS = 'DOWNLOAD_CREDENTIALS',
  EXTEND_PERIOD = 'EXTEND_PERIOD',
  EXPIRE = 'EXPIRE',
  GENERATE_CREDENTIALS = 'GENERATE_CREDENTIALS',
  INITIALIZE = 'INITIALIZE',
  LIST_EVENTS = 'LIST_EVENTS',
  LIST_HISTORY_CHANGES = 'LIST_HISTORY_CHANGES',
  REACTIVATE = 'REACTIVATE',
  REGENERATE_CREDENTIALS = 'REGENERATE_CREDENTIALS',
  RUN_DIAGNOSTIC_ROUTINE = 'RUN_DIAGNOSTIC_ROUTINE',
  SHOW_DETAILS = 'SHOW_DETAILS',
}

export type ProxyAdminPricesModel = {
  unitPrice: Nullable<string>;
  totalValue: Nullable<string>;
};

export type ProxyAdminServerModel = {
  id: string;
  name: string;
  ip: string;
};

export type ProxyAdminSubnetModel = {
  id: string;
  subnetCidr: string;
  status: ProxySubnetStatus;
};

export type ProxyAdminVendorModel = {
  urlText: string;
  url: string;
};

export type ProxyAuthenticationModel = {
  whitelistedIps: string[];
  username: Nullable<string>;
  password: Nullable<string>;
};

export type ProxyBandwidthModel = {
  total: Nullable<number>;
  used: Nullable<number>;
  updatedAt: string;
};

export type ProxyConnectionModel = {
  publicIp: Nullable<string>;
  connectIp: Nullable<string>;
  ipVersion: IPVersion;
  lastIp: Nullable<string>;
  httpPort: Nullable<number>;
  httpsPort: Nullable<number>;
  socks5Port: Nullable<number>;
  hostnames: Nullable<Record<string, string>>;
};

export type ProxyLocationModel = {
  country: Nullable<Option<string>>;
  region: Nullable<Option<string>>;
};

export type ProxyRouteModel = {
  id: number;
  proxyId: number;
  publicIp: string;
  port: number;
  serverId: string;
  serverName: string;
};

export enum ProxyStatus {
  PENDING = 'PENDING',
  INITIATING = 'INITIATING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  EXPIRING_SOON = 'EXPIRING_SOON',
}
// #endregion Common

/**
 * @POST /proxies/bulk/auto-extend/details
 */
export type BulkAutoExtendSettingsModel = {
  isBandwidthRequired: boolean;
  isAutoExtendEnabled: boolean;
};

/**
 * @GET /proxies/{id}/change-authentication-type
 */
export type ChangeAuthenticationTypeModel = {
  currentAuthenticationType: AuthenticationType;
  availableAuthenticationTypes: AuthenticationType[];
};

/**
 * @GET /proxies/{id}/change-protocol
 */
export type ChangeProtocolModel = {
  currentType: Protocol;
  availableTypes: Protocol[];
};

/**
 * @GET /proxies/{id}/change-threads
 */
export type ChangeThreadsModel = {
  currentThreads: number;
  options: number[];
};

/**
 * @GET /proxies/{id}/change-uplink-speed
 */
export type ChangeUplinkSpeedModel = {
  currentUplinkSpeed: number;
  options: number[];
};

/**
 * @POST /proxies/{id}/admin/diagnostic-routine
 */
export type DiagnosticRoutineModel = {
  url: string;
  statusCode: number;
  statusMessage: string;
  time: number;
  metadata: Record<string, string>;
};

/**
 * @GET /proxies/settings/global-ip-whitelist
 * @GET /proxies/{id}/whitelist-ip
 */
export type IPWhitelistModel = {
  whitelistedIps: string[];
  allowedWhitelistIpCount: number;
};

/**
 * @GET /proxies/{id}/credential-generator
 */
type PacketStreamHostnameModel = {
  hostnameIp: string;
  alias: ProxyPropositionAlias.PacketStream;
  region: null;
};

type NetNutHostnameModel = {
  hostnameIp: string;
  alias: ProxyPropositionAlias.NetNutMobile | ProxyPropositionAlias.NetNutResidential;
  region: NetNutHostnameRegion;
};

export type HostnameModel = PacketStreamHostnameModel | NetNutHostnameModel;

/**
 * @GET /services/proxies
 */
export type ProxyModel = {
  id: number;
  status: ProxyStatus;
  networkType: NetworkType;
  authentication: ProxyAuthenticationModel;
  connection: ProxyConnectionModel;
  proxyType: Protocol;
  createdAt: string;
  expiresAt: Nullable<string>;
  ispName: Nullable<string>;
  bandwidth: ProxyBandwidthModel;
  uplinkSpeed: Nullable<number>;
  threads: Nullable<number>;
  location: ProxyLocationModel;
  note: Nullable<string>;
  actions: ProxyActionModel[];
  maintenanceWindows: MaintenanceWindowModel[];
};

/**
 * @GET /proxies/{id}/admin/details
 */
export type ProxyAdminDetailsModel = {
  userName: string;
  userId: number;
  proposition: Nullable<string>;
  reservedIp: Nullable<string>;
  server: Nullable<ProxyAdminServerModel>;
  subnet: Nullable<ProxyAdminSubnetModel>;
  isUsed: boolean;
  isHttpsProtocolSupported: boolean;
  vendor: ProxyAdminVendorModel[];
  prices: Nullable<ProxyAdminPricesModel>;
  routes: ProxyRouteModel[];
  type: 'basic' | 'standard' | 'premium';
};

/**
 *  @GET /proxies/{id}/change-history
 */
export type ProxyChangelogModel = {
  id: string;
  oldValue: string;
  newValue: string;
  createdAt: string;
  fieldName: string;
  changedBy: Nullable<string>;
  userId: Nullable<number>;
};

/**
 * @POST /proxies/bulk/extend-period/price
 * @POST /proxies/bulk/buy-traffic/price
 * @POST /proxies/{id}/period-extension-price
 * @POST /proxies/{id}/bandwidth-price
 * @POST /proxies/{id}/reactivate/price
 */
export type ProxyExtensionPriceModel = {
  finalPrice: number;
  priceNoDiscounts: number;
  discount: number;
  unitPrice: number;
  additionalAmount: number;
  additionalAmountAfterDiscount: number;
  paymentFee: number;
  subtotal: number;
  discountAmount: number;
  finalPriceInCurrency: number;
  currency: Nullable<Currency>;
};

/**
 * @GET /proxies/{id}/events
 */
export type ProxyEventModel = {
  id: string;
  createdAt: string;
  message: string;
};

/**
 * @GET /services/proxies/isp
 */
export type ProxyISPModel = {
  id: string;
  name: string;
};

/**
 * @GET /services/proxies/{id}
 */
export type ProxyOverviewModel = ProxyModel & {
  updatedAt: Nullable<string>;
  activatedAt: Nullable<string>;
  isAutoExtendEnabled: boolean;
  autoExtendBandwidthAmount: number;
  routes: ProxyRouteModel[];
};

/**
 * @GET /services/proxies/replacement/list
 */
export type ProxyReplacementDetailsModel = {
  id: number;
  status: ProxyStatus;
  networkType: NetworkType;
  publicIp: string;
  createdAt: string;
  expiresAt: string;
  ispId: string;
  countryCode: string;
  note: Nullable<string>;
  isUnusedProxy: boolean;
  ipVersion: Nullable<IPVersion>;
};

/**
 * @POST /order/configuration
 */
export type ProxyReplacementOptions = {
  country: CountryOption[];
  isps: ISPOption[];
};

export type ProxyReplacementOptionsModel = PartialRecord<
  NetworkType,
  PartialRecord<ProxyType, ProxyReplacementOptions>
>;

/**
 * @GET /proxies/{id}/admin/routes/details
 */
export type ProxySubnetModel = {
  id: string;
  label: string;
  countryCode: string;
};

/**
 * @GET /proxies/summary
 */
export type ProxySummaryModel = {
  active: number;
  expiring: number;
  inactive: number;
};

/**
 * @GET /proxies/{id}/buy-threads
 */
export type UpgradeThreadsPriceModel = {
  thread: number;
  price: number;
};

/**
 * @GET /proxies/{id}/buy-uplink-speed
 */
export type UpgradeUplinkSpeedPriceModel = {
  mbit: number;
  price: number;
};
