import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { paths } from 'paths';
import type { ContextChildren, Nullable, Optional, ZendeskConversationField, ZendeskCookieRange } from 'types';

import { initialize } from './helpers';

type TZendeskContext = {
  show: () => void;
  hide: () => void;
  open: () => void;
  close: () => void;

  setLocale: (newLocale: string) => void;
  setZIndex: (newZIndex: number) => void;
  setCookies: (range: ZendeskCookieRange) => void;

  setConversationFields: (conversationFields: ZendeskConversationField[]) => void;
  setConversationTags: (conversationTags: string[]) => void;

  isOpen: boolean;
  isLoaded: boolean;

  unreadMessages: Nullable<number>;
};

const ZendeskContext = createContext<Optional<TZendeskContext>>(undefined);

type ZendeskProviderProps = {
  children: ContextChildren<TZendeskContext>;
};

export function ZendeskProvider({ children }: ZendeskProviderProps) {
  const { pathname } = useLocation();

  const isLoaded = useRef(false);

  const [isOpen, setOpen] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState<Nullable<number>>(null);

  const shouldBeVisible = useMemo(() => {
    return ![
      paths.order.checkout,
      paths.order.details,
      paths.order.proxy,
      paths.order.v2.checkout,
      paths.order.v2.details,
      paths.order.v2.index,
    ].includes(pathname);
  }, [pathname]);

  const register = useCallback(() => {
    if (isLoaded.current) return;

    window.zE?.('messenger:on', 'open', () => setOpen(true));

    window.zE?.('messenger:on', 'close', () => setOpen(false));

    window.zE?.('messenger:on', 'unreadMessages', (messages) => setUnreadMessages(messages));

    window.zE?.('messenger:set', 'zIndex', shouldBeVisible ? 999999 : -1);

    isLoaded.current = true;
  }, [shouldBeVisible]);

  useEffect(() => {
    initialize('d13e103f-27f6-40b8-a586-70f1edfd4b47', register);
  }, [register]);

  const show = useCallback(() => window.zE?.('messenger', 'show'), []);

  const hide = useCallback(() => window.zE?.('messenger', 'hide'), []);

  const open = useCallback(() => {
    if (!shouldBeVisible) window.zE?.('messenger:set', 'zIndex', 999999);

    window.zE?.('messenger', 'open');
  }, [shouldBeVisible]);

  const close = useCallback(() => {
    window.zE?.('messenger', 'close');

    if (!shouldBeVisible) window.zE?.('messenger:set', 'zIndex', -1);
  }, [shouldBeVisible]);

  const setLocale = useCallback((locale: string) => window.zE?.('messenger:set', 'locale', locale), []);

  const setZIndex = useCallback((zIndex: number) => window.zE?.('messenger:set', 'zIndex', zIndex), []);

  const setCookies = useCallback((range: ZendeskCookieRange) => window.zE?.('messenger:set', 'cookies', range), []);

  const setConversationFields = useCallback(
    (conversationFields: ZendeskConversationField[]) =>
      window.zE?.('messenger:set', 'conversationFields', conversationFields),
    [],
  );

  const setConversationTags = useCallback(
    (conversationTags: string[]) => window.zE?.('messenger:set', 'conversationTags', conversationTags),
    [],
  );

  const value = useMemo<TZendeskContext>(() => {
    return {
      close,
      hide,
      isLoaded: isLoaded.current,
      isOpen,
      open,
      setConversationFields,
      setConversationTags,
      setCookies,
      setLocale,
      setZIndex,
      show,
      unreadMessages,
    };
  }, [
    close,
    hide,
    isOpen,
    open,
    setConversationFields,
    setConversationTags,
    setCookies,
    setLocale,
    setZIndex,
    show,
    unreadMessages,
  ]);

  return (
    <ZendeskContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </ZendeskContext.Provider>
  );
}

export function useZendesk() {
  const context = useContext(ZendeskContext);

  if (context === undefined) {
    throw new Error('Cannot use "useZendesk" outside the "<ZendeskProvider />" context provider');
  }

  return context;
}
