import { type MouseEvent, useCallback, useMemo } from 'react';

import Chip, { chipClasses } from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { Link, type To, useLocation, useNavigate } from 'react-router-dom';

import { Button, type ButtonProps } from 'components/Button';
import { posthogEvents } from 'constants/posthogEvents';
import { useIsNewCustomer } from 'hooks/useIsNewCustomer';
import { useOrderTest } from 'hooks/useOrderTest';
import { useOrderFlow } from 'modules/orders/v1/hooks';
import { useOrderState } from 'modules/orders/v2/hooks';
import { paths } from 'paths';
import { useAppDispatch } from 'store';
import { resetOrderFlow } from 'store/orders/v1/reducers';
import type { Nullable } from 'types';

type Props = Omit<ButtonProps, 'onClick'> & {
  isBlackFridayAllowedForUser?: boolean;
  isExperimentLoading?: boolean;
  experimentCouponCode?: Nullable<string>;
};

export function OrderProxyButton({
  size = 'medium',
  isBlackFridayAllowedForUser,
  isExperimentLoading,
  ...props
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useAppDispatch();

  const posthog = usePostHog();
  const isNewCustomer = useIsNewCustomer();

  const { shouldDisplayNewOrderFlow } = useOrderTest();

  const { isNetworkTypeSelected, isVPNFlow, isValidNetworkType, isMarketplaceFlow, startNewOrderFlow } = useOrderFlow();
  const resetOrder = useOrderState((state) => state.resetOrder);

  const href = useMemo<To>(() => {
    if (shouldDisplayNewOrderFlow) {
      return { pathname: paths.order.v2.index, search };
    }

    return { pathname: paths.order.index, search };
  }, [search, shouldDisplayNewOrderFlow]);

  const handleOpenCurrentFlow = useCallback(() => {
    // Reset exsiting order flow and start new Proxy order flow
    if (isVPNFlow || !isValidNetworkType) {
      dispatch(resetOrderFlow());
      startNewOrderFlow(null);

      return navigate({ pathname: paths.order.index, search });
    }

    // Start new Proxy flow
    if (!isNetworkTypeSelected) {
      startNewOrderFlow(null);

      return navigate({ pathname: paths.order.index, search });
    }

    // Continue previous order flow
    posthog?.capture(posthogEvents.order.continued, { isMarketplaceFlow, isNewCustomer });

    return navigate({ pathname: paths.order.index, search });
  }, [
    dispatch,
    isMarketplaceFlow,
    isNetworkTypeSelected,
    isNewCustomer,
    isVPNFlow,
    isValidNetworkType,
    navigate,
    posthog,
    search,
    startNewOrderFlow,
  ]);

  const handleOpenNewFlow = useCallback(() => {
    posthog?.capture(posthogEvents.order.v2.started);

    resetOrder();

    return navigate({ pathname: paths.order.v2.index, search });
  }, [navigate, posthog, resetOrder, search]);

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();

      if (shouldDisplayNewOrderFlow) {
        return handleOpenNewFlow();
      }

      handleOpenCurrentFlow();
    },
    [handleOpenCurrentFlow, handleOpenNewFlow, shouldDisplayNewOrderFlow],
  );

  const content = useMemo(() => {
    if (isExperimentLoading) return <Skeleton width="100%" height={48} variant="rounded" />;

    if (isBlackFridayAllowedForUser) {
      return (
        <Stack
          spacing={1}
          bgcolor="var(--mui-palette-neutral-950)"
          p={2}
          borderRadius={2}
          alignItems="center"
          textAlign="center"
        >
          <Chip
            label={t('common:experiments.blackFriday.label')}
            color="warning"
            sx={{
              [`&.${chipClasses.colorWarning}`]: { backgroundColor: 'var(--mui-palette-warning-300)' },

              [`& .${chipClasses.label}`]: { color: 'var(--mui-palette-neutral-950)' },
            }}
          />

          <Typography variant="headline-2" color="white">
            {t('common:experiments.blackFriday.content')}
          </Typography>

          <Button
            component={Link}
            to={{ pathname: paths.order.index, search }}
            onClick={onClick}
            size={size}
            color="error"
            {...props}
          >
            {t('common:navbar.orderProxy')}
          </Button>
        </Stack>
      );
    }

    return (
      <Button component={Link} to={href} onClick={onClick} size={size} {...props}>
        {t('common:navbar.orderProxy')}
      </Button>
    );
  }, [href, isBlackFridayAllowedForUser, isExperimentLoading, onClick, props, search, size, t]);

  return content;
}
