import { useMemo } from 'react';

import { useSearchParams } from 'react-router-dom';

import type { Optional } from 'types';

export type OrderFlowTestVariant = 'control' | 'test-b-period' | 'test-c-period';

type OrderTest = {
  variant: OrderFlowTestVariant;
  isOrderBVariant: boolean;
  shouldDisplayNewOrderFlow: boolean;
};

// TODO: Change this to `control`
const DEFAULT_VARIANT: OrderFlowTestVariant = 'control';

export function useOrderTest() {
  const [searchParams] = useSearchParams();

  const variant = useMemo(
    () => (searchParams.get('testVariant') as Optional<OrderFlowTestVariant>) ?? DEFAULT_VARIANT,
    [searchParams],
  );

  // const variant = useFeatureFlagVariantKey(POSTHOG_ORDER_FLOW_TEST_KEY);

  const shouldDisplayNewOrderFlow = useMemo(() => {
    const notAllowedFor: OrderFlowTestVariant[] = ['control'];

    return !notAllowedFor.includes(variant ?? DEFAULT_VARIANT);
  }, [variant]);

  const isOrderBVariant = useMemo(() => {
    const allowedFor: OrderFlowTestVariant[] = ['test-b-period'];

    return shouldDisplayNewOrderFlow && allowedFor.includes(variant ?? DEFAULT_VARIANT);
  }, [shouldDisplayNewOrderFlow, variant]);

  return useMemo<OrderTest>(() => {
    return {
      shouldDisplayNewOrderFlow,
      isOrderBVariant,
      variant: variant ?? DEFAULT_VARIANT,
    };
  }, [isOrderBVariant, shouldDisplayNewOrderFlow, variant]);
}
