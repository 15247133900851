import { type ReactNode, useEffect, useState } from 'react';

import { Outlet } from 'react-router-dom';

import { useOrderTest } from 'hooks/useOrderTest';
import { useRouter } from 'hooks/useRouter';
import { paths } from 'paths';
import { isAdminUser } from 'utils/user';

type OrderGuardProps = {
  children?: ReactNode;
};

export function OrderGuard({ children }: OrderGuardProps) {
  const [checked, setChecked] = useState(false);
  const router = useRouter();

  const { shouldDisplayNewOrderFlow, variant } = useOrderTest();

  useEffect(() => {
    if (isAdminUser()) {
      setChecked(true);

      return;
    }

    if (!variant || variant === 'control') {
      setChecked(true);

      return router.replace(paths.order.index);
    }

    // Users should not be allowed to access this page unless they participate in the `test-b` variant
    // TODO: This might need to be extended when more variants added
    if (!shouldDisplayNewOrderFlow) {
      setChecked(true);

      return router.replace(paths.order.index);
    }

    if (shouldDisplayNewOrderFlow) {
      setChecked(true);

      return router.replace(paths.order.v2.index);
    }

    setChecked(true);
  }, [router, shouldDisplayNewOrderFlow, variant]);

  if (!checked) return null;

  return children ?? <Outlet />;
}
