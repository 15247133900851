import { type SyntheticEvent, useMemo } from 'react';

import Collapse from '@mui/material/Collapse';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MuiInput from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput, { type OutlinedInputProps } from '@mui/material/OutlinedInput';

import { type NumberFormatValues, NumericFormat } from 'react-number-format';

import { Icon, isIconName, type IconName } from 'components/Icon';
import type { LiteralNodeUnion, Nullable } from 'types';

export type NumberInputProps<Value extends number> = Omit<
  OutlinedInputProps,
  'name' | 'id' | 'error' | 'value' | 'onChange' | 'defaultValue' | 'type'
> & {
  name?: string;
  label?: string;
  type?: 'text' | 'tel' | 'password';

  value?: Value;
  defaultValue?: Nullable<Value>;
  hasError?: boolean;
  error?: string;
  helperText?: string;

  onChange?: (value: NumberFormatValues, event?: SyntheticEvent<HTMLInputElement>) => void;

  endIcon?: LiteralNodeUnion<IconName>;
  startIcon?: LiteralNodeUnion<IconName>;

  prefix?: string;
  suffix?: string;

  decimalScale?: number;
  allowLeadingZeros?: boolean;
  allowNegative?: boolean;
  isAllowed?: (values: NumberFormatValues) => boolean;

  variant?: 'outlined' | 'standard' | 'filled';
};

export function NumberInput<Value extends number>({
  name,
  label,
  type,

  fullWidth,
  disabled,
  size,

  value,
  defaultValue,
  hasError,
  error,
  helperText,

  onChange,

  endIcon,
  startIcon,

  prefix,
  suffix,

  decimalScale,
  allowLeadingZeros = false,
  allowNegative = false,
  isAllowed,

  endAdornment,
  startAdornment,

  variant = 'outlined',
  ...props
}: NumberInputProps<Value>) {
  const InputComponent = useMemo(() => {
    if (variant === 'filled') return FilledInput;

    if (variant === 'standard') return MuiInput;

    return OutlinedInput;
  }, [variant]);

  const end = useMemo(() => {
    if (isIconName(endIcon)) {
      return (
        <InputAdornment position="end">
          <Icon name={endIcon} size="small" />
        </InputAdornment>
      );
    }

    if (endIcon || endAdornment) {
      return <InputAdornment position="end">{endIcon ?? endAdornment}</InputAdornment>;
    }

    return null;
  }, [endAdornment, endIcon]);

  const start = useMemo(() => {
    if (isIconName(startIcon)) {
      return (
        <InputAdornment position="start">
          <Icon name={startIcon} size="small" />
        </InputAdornment>
      );
    }

    if (startIcon || startAdornment) {
      return <InputAdornment position="start">{startIcon ?? startAdornment}</InputAdornment>;
    }

    return null;
  }, [startAdornment, startIcon]);

  return (
    <FormControl error={hasError} fullWidth={fullWidth} disabled={disabled}>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}

      <NumericFormat
        {...props}
        id={name}
        name={name}
        type={type}
        endAdornment={end}
        startAdornment={start}
        aria-labelledby={name}
        value={value}
        error={hasError}
        disabled={disabled}
        fullWidth={fullWidth}
        size={size}
        prefix={prefix}
        suffix={suffix}
        allowNegative={allowNegative}
        isAllowed={isAllowed}
        decimalScale={decimalScale}
        allowLeadingZeros={allowLeadingZeros}
        onValueChange={(newValue, { event }) => onChange?.(newValue, event)}
        customInput={InputComponent}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      />

      <Collapse in={hasError || !!helperText} unmountOnExit>
        <FormHelperText error={hasError}>{hasError ? error?.toString() : helperText}</FormHelperText>
      </Collapse>
    </FormControl>
  );
}
