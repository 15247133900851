import { useCallback, useMemo } from 'react';

import type { PlanID, ServiceID } from 'store/orders/v2/types';
import { IPVersion, NetworkType } from 'store/proxies/types';
import type { Nullable } from 'types';

type GetServiceAndPlanIDsResult = {
  serviceId: ServiceID;
  planId: Nullable<PlanID>;
};

export function useOrderBackwardCompatibility() {
  const getServiceAndPlanIDs = useCallback(
    (networkType: NetworkType, isUnusedProxy: boolean, ipVersion?: IPVersion): GetServiceAndPlanIDsResult => {
      if (networkType === NetworkType.Mobile) {
        return { serviceId: 'rotating-mobile', planId: null };
      }

      if (networkType === NetworkType.Residential) {
        return { serviceId: 'rotating-residential', planId: null };
      }

      if (networkType === NetworkType.VPN) {
        return { serviceId: 'vpn', planId: null };
      }

      // TODO: Adjust when the Static Residential IPv6 will be introduced
      if (networkType === NetworkType.ResidentialStatic) {
        return { serviceId: 'static-residential-ipv4', planId: isUnusedProxy ? 'premium' : 'standard' };
      }

      if (ipVersion === IPVersion.IPv4) {
        return { serviceId: 'static-datacenter-ipv4', planId: isUnusedProxy ? 'premium' : 'standard' };
      }

      return { serviceId: 'static-datacenter-ipv6', planId: null };
    },
    [],
  );

  return useMemo(() => {
    return { getServiceAndPlanIDs };
  }, [getServiceAndPlanIDs]);
}
