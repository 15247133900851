import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { DEFAULT_SETUP_VALUES } from 'modules/orders/v2/OrderSetupPage/constants';
import type { IPVersion, NetworkType } from 'store/proxies/types';

import { useOrderBackwardCompatibility } from './useOrderBackwardCompatibility';
import { useOrderState } from './useOrderState';

export function useInitOrderStateFromParams() {
  const [searchParams] = useSearchParams();
  const [isInitialized, setInitialized] = useState(false);

  const setCouponCode = useOrderState((state) => state.setCouponCode);
  const setSelectedPlanId = useOrderState((state) => state.setSelectedPlanId);
  const setSelectedServiceId = useOrderState((state) => state.setSelectedServiceId);
  const setSelectedSetup = useOrderState((state) => state.setSelectedSetup);

  const initializeOrderFlow = useOrderState((state) => state.initializeOrderFlow);

  const { getServiceAndPlanIDs } = useOrderBackwardCompatibility();

  useEffect(() => {
    const parsedNetworkType = (searchParams.get('networkType') as NetworkType) ?? undefined;
    const parsedTimePeriod = searchParams.get('timePeriod') ?? undefined;
    const parsedIsUnusedProxy = searchParams.get('isUnusedProxy') ?? undefined;
    const parsedBandwidth = searchParams.get('bandwidth') ?? undefined;
    const parsedPackage = searchParams.get('package') ?? undefined;
    const parsedIPVersion = (searchParams.get('ipVersion') as IPVersion) ?? undefined;
    const parsedCountry = searchParams.get('country') ?? undefined;
    const parsedCouponCode = searchParams.get('couponCode') ?? undefined;
    const parsedIsp = searchParams.get('isp') ?? undefined;
    const parsedQuantity = searchParams.get('quantity') ?? undefined;

    if (parsedCouponCode) {
      setCouponCode(parsedCouponCode);
    }

    if (parsedNetworkType) {
      const { planId, serviceId } = getServiceAndPlanIDs(
        parsedNetworkType,
        parsedIsUnusedProxy === 'true',
        parsedIPVersion,
      );

      initializeOrderFlow({
        ...DEFAULT_SETUP_VALUES,
        serviceId,
        planId,
        country: parsedCountry,
        isp: parsedIsp,
        package: parsedPackage ?? DEFAULT_SETUP_VALUES.package,
        period: parsedTimePeriod ?? DEFAULT_SETUP_VALUES.period,
        quantity: parsedQuantity ? parseInt(parsedQuantity, 10) : DEFAULT_SETUP_VALUES.quantity,
        bandwidth: parsedBandwidth ? parseInt(parsedBandwidth, 10) : DEFAULT_SETUP_VALUES.bandwidth,
      });
    }

    setInitialized(true);
  }, [
    getServiceAndPlanIDs,
    initializeOrderFlow,
    searchParams,
    setCouponCode,
    setSelectedPlanId,
    setSelectedServiceId,
    setSelectedSetup,
  ]);

  return { isInitialized };
}
