import type { AxiosBaseQueryError } from 'store/types';
import type { Nullable, Option, Optional } from 'types';

export type ProxyType = 'standard' | 'premium';

export enum ProxyPropositionAlias {
  NetNutMobile = 'NETNUT_MOBILE',
  NetNutResidential = 'NETNUT_RESIDENTIAL',
  PacketStream = 'PACKETSTREAM_RESIDENTIAL',
}

export type NetNutHostnameRegion = 'US' | 'EU';

export type ProxiesState = {
  replaceProgress: number;
  proxiesWithErrors: number[];

  replacementErrors: Nullable<Record<number, Optional<AxiosBaseQueryError>>>;
};

// TODO: Change this type to use Parameters instead of extending type
export type CountryOption = Option<string> & {
  isUnused: boolean;
  plainAmount: Nullable<string>;
};

// #region Common
export enum AuthenticationType {
  IP_WHITELIST = 'IP_WHITELIST',
  USERNAME_PASSWORD = 'USERNAME_PASSWORD',
}

export enum IPVersion {
  IPv4 = 'IPv4',
  IPv6 = 'IPv6',
}

export enum NetworkType {
  Mobile = 'MOBILE',
  Residential = 'RESIDENTIAL',
  ResidentialStatic = 'RESIDENTIAL_STATIC',
  Datacenter = 'DATACENTER',
  VPN = 'VPN',
}

export enum Protocol {
  SOCKS5 = 'SOCKS5',
  HTTP = 'HTTP',
  HTTPS = 'HTTPS',
}

export type ProxyCredentialsFormat = 'IP_PORT' | 'IP_PORT_USERNAME_PASSWORD' | 'USERNAME_PASSWORD_IP_PORT';

export enum ProxySubnetStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  DRAINING = 'DRAINING',
  NEW_ORDERS_PAUSED = 'NEW_ORDERS_PAUSED',
}

export enum ServiceType {
  PROXY_MOBILE = 'PROXY_MOBILE',
  PROXY_RESIDENTIAL = 'PROXY_RESIDENTIAL',
  PROXY_RESIDENTIAL_STATIC = 'PROXY_RESIDENTIAL_STATIC',
  PROXY_DATACENTER = 'PROXY_DATACENTER',
  VPN = 'VPN',
}

// #endregion Common
