import type { AppEndpointBuilder } from 'store/types';

import {
  buildServiceDetailsPayload,
  toOrderSetupExecuteModel,
  toOrderSetupModel,
  toOrderSetupPriceModel,
  toServiceModel,
} from './helpers';
import type { OrderSetupExecuteModel, OrderSetupModel, OrderSetupPriceModel, ServiceModel } from './models';
import type { OrderExecutePayload, OrderPricePayload, ServiceDetailsPayload } from './payloads';

// TODO: Add cache keys, etc.
export const orderEndpoints = (builder: AppEndpointBuilder) => ({
  // #region Queries
  getOrderServices: builder.query<ServiceModel[], void>({
    query: () => ({
      url: 'v2/order',
      method: 'GET',
      params: { withMetadata: true, withNonOrderable: true },
    }),
    providesTags: ['Order-Services'],
    transformResponse: toServiceModel,
  }),

  getOrderServiceDetails: builder.query<OrderSetupModel, ServiceDetailsPayload>({
    query: (payload) => ({
      url: `v2/order/${payload?.service.id}`,
      method: 'POST',
      params: { withMetadata: true },
      data: buildServiceDetailsPayload(payload),
    }),
    providesTags: (_, __, { service }) => [{ type: 'Order-Service-Details', id: service.id }],
    transformResponse: toOrderSetupModel,
  }),
  // #endregion Queries

  // #region Mutations
  getOrderServicePrice: builder.mutation<OrderSetupPriceModel, OrderPricePayload>({
    query: ({ serviceId, ...payload }) => ({
      url: `v2/order/${serviceId}/price`,
      method: 'POST',
      params: { withMetadata: true },
      data: payload,
    }),
    transformResponse: toOrderSetupPriceModel,
  }),

  executeServiceOrder: builder.mutation<OrderSetupExecuteModel, OrderExecutePayload>({
    query: ({ serviceId, ...payload }) => ({
      url: `v2/order/${serviceId}/execute`,
      method: 'POST',
      data: payload,
    }),
    transformResponse: toOrderSetupExecuteModel,
    invalidatesTags: ['Account-Balance', 'Proxy-Summary', 'Proxy', 'Session', 'Experiments'],
  }),
  // #endregion Mutations
});
