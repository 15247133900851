import { ConfirmationModal, type ConfirmationModalProps } from 'components/modals/ConfirmationModal';
import { createModalManager } from 'components/modals/context';
import type { ModalStateType } from 'components/modals/types';
import {
  DownloadInvoicesModal,
  type DownloadInvoicesModalProps,
  PasswordModal,
  type PasswordModalProps,
  SoftwareTwoFactorAuthenticationModal,
  type SoftwareTwoFactorAuthenticationModalProps,
  VerifyEmailModal,
} from 'modules/accounts/modals';
import {
  AddCreditCardModal,
  type AddCreditCardModalProps,
  ManageCreditCardModal,
  type ManageCreditCardModalProps,
  TopUpModal,
  type TopUpModalProps,
} from 'modules/billing/modals';
import {
  CountryRequestModal,
  type CountryRequestModalProps,
  CouponCodeModal,
  type CouponCodeModalProps,
  ExperienceCheckModal,
  OrderCheckoutStatusModal,
  type OrderCheckoutStatusModalProps,
} from 'modules/orders/v1/modals';
import {
  CouponCodeModal as CouponCodeModal2,
  type CouponCodeModalProps as CouponCodeModalProps2,
} from 'modules/orders/v2/modals';
import {
  AdminAddRouteModal,
  type AdminAddRouteModalProps,
  AdminChangePortModal,
  type AdminChangePortModalProps,
  AdminChangelogModal,
  type AdminChangelogModalProps,
  AdminDiagnosticModal,
  type AdminDiagnosticModalProps,
  BulkAutoExtendModal,
  type BulkAutoExtendModalProps,
  ChangeAuthenticationMethodModal,
  type ChangeAuthenticationMethodModalProps,
  ChangeProxyProtocolModal,
  type ChangeProxyProtocolModalProps,
  ChangeThreadsModal,
  type ChangeThreadsModalProps,
  ChangeUplinkSpeedModal,
  type ChangeUplinkSpeedModalProps,
  DownloadCredentialsModal,
  type DownloadCredentialsModalProps,
  ExtendBandwidthModal,
  type ExtendBandwidthModalProps,
  ExtendProxyPeriodModal,
  type ExtendProxyPeriodModalProps,
  GlobalIPWhitelistModal,
  type GlobalIPWhitelistModalProps,
  ProxyAdminEventsModal,
  type ProxyAdminEventsModalProps,
  ProxyAutoExtendBandwidthModal,
  type ProxyAutoExtendBandwidthModalProps,
  ProxyAutoExtendModal,
  type ProxyAutoExtendModalProps,
  ProxyNoteModal,
  type ProxyNoteModalProps,
  ReactivateProxyModal,
  type ReactivateProxyModalProps,
  ReplaceProxiesProgressModal,
  type ReplaceProxiesProgressModalProps,
  UpgradeThreadsModal,
  type UpgradeThreadsModalProps,
  UpgradeUplinkSpeedModal,
  type UpgradeUplinkSpeedModalProps,
} from 'modules/proxies/modals';
import {
  PayoutModal,
  PayoutNotPossibleModal,
  PayoutSetupModal,
  type PayoutSetupModalProps,
} from 'modules/referrals/modals';
import {
  VPNAdminEventsModal,
  type VPNAdminEventsModalProps,
  VPNAutoExtendModal,
  type VPNAutoExtendModalProps,
  VPNChangelogModal,
  type VPNChangelogModalProps,
  VPNExtendPeriodModal,
  type VPNExtendPeriodModalProps,
} from 'modules/vpns/modals';

type ModalMapType =
  | ModalStateType<'credit.card.add', AddCreditCardModalProps>
  | ModalStateType<'credit.card.remove', ConfirmationModalProps>
  | ModalStateType<'coupon', CouponCodeModalProps>
  | ModalStateType<'orders.coupon', CouponCodeModalProps2>
  | ModalStateType<'orders.cancel', ConfirmationModalProps>
  | ModalStateType<'proxies.admin.https.toggle', ConfirmationModalProps>
  | ModalStateType<'orders.experience.check'>
  | ModalStateType<'account.totp', SoftwareTwoFactorAuthenticationModalProps>
  | ModalStateType<'account.password', PasswordModalProps>
  | ModalStateType<'account.id.verification', ConfirmationModalProps>
  | ModalStateType<'account.email.verification'>
  | ModalStateType<'proxies.extend.period', ExtendProxyPeriodModalProps>
  | ModalStateType<'proxies.extend.bandwidth', ExtendProxyPeriodModalProps>
  | ModalStateType<'proxies.upgrade.bandwidth-speed', UpgradeUplinkSpeedModalProps>
  | ModalStateType<'proxies.upgrade.threads', UpgradeThreadsModalProps>
  | ModalStateType<'proxies.auto.extend', ProxyAutoExtendModalProps>
  | ModalStateType<'proxies.auto.extend.bandwidth', ProxyAutoExtendBandwidthModalProps>
  | ModalStateType<'proxies.ip.whitelist.global', GlobalIPWhitelistModalProps>
  | ModalStateType<'proxies.credentials.download', DownloadCredentialsModalProps>
  | ModalStateType<'proxies.reactivate', ReactivateProxyModalProps>
  | ModalStateType<'proxies.change-protocol', ChangeProxyProtocolModalProps>
  | ModalStateType<'proxies.change.auth-method', ChangeAuthenticationMethodModalProps>
  | ModalStateType<'proxies.change.uplink-speed', ChangeUplinkSpeedModalProps>
  | ModalStateType<'proxies.change.threads', ChangeThreadsModalProps>
  | ModalStateType<'proxies.note', ProxyNoteModalProps>
  | ModalStateType<'proxies.replace.progress', ReplaceProxiesProgressModalProps>
  | ModalStateType<'vpns.auto.extend', VPNAutoExtendModalProps>
  | ModalStateType<'vpns.admin.changelog', VPNChangelogModalProps>
  | ModalStateType<'vpns.admin.events', VPNAdminEventsModalProps>
  | ModalStateType<'vpn.extend.period', VPNExtendPeriodModalProps>
  | ModalStateType<'credit.card.manage', ManageCreditCardModalProps>
  | ModalStateType<'account.billing.topUp', TopUpModalProps>
  | ModalStateType<'referrals.payout'>
  | ModalStateType<'referrals.payout-not-possible'>
  | ModalStateType<'referrals.payout-setup', PayoutSetupModalProps>
  | ModalStateType<'account.billing.topUp.confirmation', ConfirmationModalProps>
  | ModalStateType<'proxies.cancel.confirmation', ConfirmationModalProps>
  | ModalStateType<'vpns.cancel.confirmation', ConfirmationModalProps>
  | ModalStateType<'api-keys.delete', ConfirmationModalProps>
  | ModalStateType<'order.status', OrderCheckoutStatusModalProps>
  | ModalStateType<'order.no-deals', ConfirmationModalProps>
  | ModalStateType<'admin.proxies.events', ProxyAdminEventsModalProps>
  | ModalStateType<'admin.proxies.changelog', AdminChangelogModalProps>
  | ModalStateType<'admin.proxies.port.change', AdminChangePortModalProps>
  | ModalStateType<'account.invoices.download', DownloadInvoicesModalProps>
  | ModalStateType<'proxies.auto.extend.bulk', BulkAutoExtendModalProps>
  | ModalStateType<'proxies.admin.route.add', AdminAddRouteModalProps>
  | ModalStateType<'proxies.admin.route.delete', ConfirmationModalProps>
  | ModalStateType<'admin.proxies.diagnostic', AdminDiagnosticModalProps>
  | ModalStateType<'order.request.country', CountryRequestModalProps>;

const { Provider, useShowModal } = createModalManager(({ id, params }: ModalMapType) => {
  switch (id) {
    case 'credit.card.add':
      return <AddCreditCardModal {...params} />;

    case 'account.billing.topUp':
      return <TopUpModal {...params} />;

    case 'credit.card.manage':
      return <ManageCreditCardModal {...params} />;

    case 'coupon':
      return <CouponCodeModal {...params} />;

    case 'orders.coupon':
      return <CouponCodeModal2 {...params} />;

    case 'admin.proxies.diagnostic':
      return <AdminDiagnosticModal {...params} />;

    case 'orders.cancel':
    case 'order.no-deals':
    case 'credit.card.remove':
    case 'account.id.verification':
    case 'account.billing.topUp.confirmation':
    case 'api-keys.delete':
    case 'proxies.cancel.confirmation':
    case 'proxies.admin.https.toggle':
    case 'proxies.admin.route.delete':
    case 'vpns.cancel.confirmation':
      return <ConfirmationModal {...params} />;

    case 'proxies.admin.route.add':
      return <AdminAddRouteModal {...params} />;

    case 'account.totp':
      return <SoftwareTwoFactorAuthenticationModal {...params} />;

    case 'account.password':
      return <PasswordModal {...params} />;

    case 'proxies.extend.period':
      return <ExtendProxyPeriodModal {...params} />;

    case 'proxies.upgrade.bandwidth-speed':
      return <UpgradeUplinkSpeedModal {...params} />;

    case 'proxies.upgrade.threads':
      return <UpgradeThreadsModal {...params} />;

    case 'proxies.extend.bandwidth':
      return <ExtendBandwidthModal {...(params as ExtendBandwidthModalProps)} />;

    case 'proxies.auto.extend':
      return <ProxyAutoExtendModal {...params} />;

    case 'proxies.auto.extend.bandwidth':
      return <ProxyAutoExtendBandwidthModal {...params} />;

    case 'proxies.ip.whitelist.global':
      return <GlobalIPWhitelistModal {...params} />;

    case 'proxies.credentials.download':
      return <DownloadCredentialsModal {...params} />;

    case 'proxies.note':
      return <ProxyNoteModal {...params} />;

    case 'proxies.reactivate':
      return <ReactivateProxyModal {...params} />;

    case 'proxies.change-protocol':
      return <ChangeProxyProtocolModal {...params} />;

    case 'proxies.change.auth-method':
      return <ChangeAuthenticationMethodModal {...params} />;

    case 'proxies.change.uplink-speed':
      return <ChangeUplinkSpeedModal {...params} />;

    case 'proxies.auto.extend.bulk':
      return <BulkAutoExtendModal {...params} />;

    case 'proxies.change.threads':
      return <ChangeThreadsModal {...params} />;

    case 'vpns.auto.extend':
      return <VPNAutoExtendModal {...params} />;

    case 'vpns.admin.changelog':
      return <VPNChangelogModal {...params} />;

    case 'vpns.admin.events':
      return <VPNAdminEventsModal {...params} />;

    case 'vpn.extend.period':
      return <VPNExtendPeriodModal {...params} />;

    case 'referrals.payout':
      return <PayoutModal />;

    case 'referrals.payout-not-possible':
      return <PayoutNotPossibleModal />;

    case 'referrals.payout-setup':
      return <PayoutSetupModal {...params} />;

    case 'orders.experience.check':
      return <ExperienceCheckModal />;

    case 'order.status':
      return <OrderCheckoutStatusModal {...params} />;

    case 'account.email.verification':
      return <VerifyEmailModal />;

    case 'admin.proxies.events':
      return <ProxyAdminEventsModal {...params} />;

    case 'admin.proxies.changelog':
      return <AdminChangelogModal {...params} />;

    case 'admin.proxies.port.change':
      return <AdminChangePortModal {...params} />;

    case 'account.invoices.download':
      return <DownloadInvoicesModal {...params} />;

    case 'proxies.replace.progress':
      return <ReplaceProxiesProgressModal {...params} />;

    case 'order.request.country':
      return <CountryRequestModal {...params} />;

    default:
      return null;
  }
});

export { useShowModal };

export default { Provider };
