import isNil from 'lodash/isNil';

import { COUNTRIES } from 'constants/countries';
import { OUT_OF_STOCK } from 'constants/labels';
import type { PaymentOption } from 'modules/billing/types';
import { toCurrency } from 'store/accounts/helpers';
import { NetworkType } from 'store/proxies/types';
import type { Period, Option, PartialRecord, Nullable } from 'types';
import { parseTimePeriod } from 'utils/values';

import { SERVICE_WEIGHTS } from './constants';
import type {
  CountryAvailabilityMetadataDTO,
  ISPAvailabilityMetadataDTO,
  OrderSetupExecuteDTO,
  OrderSetupPriceAppliedDiscountsDTO,
  OrderSetupPriceLabelsDTO,
  OrderSetupPriceSpecialOfferDTO,
  OrderSetupPriceWithMetadataDTO,
  OrderSetupWithMetadataDTO,
  PackagePricingMetadataDTO,
  PeriodAvailabilityMetadataDTO,
  PeriodPricingMetadataDTO,
  PlanDTO,
  PlanPricingMetadataDTO,
  QuantityPricingMetadataDTO,
  ServicePricingMetadataDTO,
  ServicesWithMetadataDTO,
  TrafficPricingMetadataDTO,
} from './dtos';
import type {
  OrderSetupExecuteModel,
  OrderSetupFormModel,
  OrderSetupModel,
  OrderSetupPackagesParameters,
  OrderSetupPeriodsParameters,
  OrderSetupPriceLabelModel,
  OrderSetupPriceModel,
  OrderSetupPriceSpecialOfferModel,
  OrderSetupQuantityParameters,
  OrderSetupTrafficParameters,
  PlanModel,
  PlanPricingModel,
  ServiceModel,
  ServicePricingModel,
} from './models';
import type { OrderExecutePayload, OrderSetupPayload, PeriodPayload, ServiceDetailsPayload } from './payloads';
import type { PlanID, ServiceID } from './types';

// #region Utilities
function formatTimePeriod(from: Nullable<string>): PeriodPayload {
  const parsedTimePeriod = parseTimePeriod(from);

  if (!parsedTimePeriod) return { unit: 'months', value: 1 };

  const { format, value } = parsedTimePeriod;

  return { unit: format, value };
}
// #endregion Utilities

// #region Mappers for DTOs
export function toNetworkType(from: ServiceID): NetworkType {
  if (from === 'rotating-mobile') {
    return NetworkType.Mobile;
  }

  if (from === 'rotating-residential') {
    return NetworkType.Residential;
  }

  if (from === 'static-datacenter-ipv4' || from === 'static-datacenter-ipv6') {
    return NetworkType.Datacenter;
  }

  if (from === 'static-residential-ipv4') {
    return NetworkType.ResidentialStatic;
  }

  return NetworkType.VPN;
}

function toPlanPricingModel({
  discount,
  subtotal,
  subtotalAfterDiscount,
  total,
  discountAmount,
  perUnit,
  perUnitAfterDiscount,
  unit,
}: PlanPricingMetadataDTO): PlanPricingModel {
  return { discount, subtotal, subtotalAfterDiscount, total, discountAmount, unit, perUnit, perUnitAfterDiscount };
}

function toServicePricingModel({
  discount,
  subtotal,
  subtotalAfterDiscount,
  total,
  unit,
  discountAmount,
  perUnit,
  perUnitAfterDiscount,
}: ServicePricingMetadataDTO): ServicePricingModel {
  return { discount, subtotal, subtotalAfterDiscount, total, unit, discountAmount, perUnit, perUnitAfterDiscount };
}

export function toPlanModel(
  { id, label }: PlanDTO,
  pricing: PlanPricingMetadataDTO,
  availability: { plans: Record<PlanID, boolean> },
): PlanModel {
  return { id, label, pricing: toPlanPricingModel(pricing), isAvailable: availability.plans[id] };
}

export function toServiceModel({ metadata, services }: ServicesWithMetadataDTO): ServiceModel[] {
  return services
    .map<ServiceModel>(({ id, label, countries = [], plans = [] }) => {
      const servicePricing = metadata.pricing.services[id];

      if (servicePricing.plans) {
        const plansPricing = servicePricing.plans;
        const plansAvailability = metadata.availability.services;

        return {
          id,
          label,
          countries,
          plans: plans.map((plan) => toPlanModel(plan, plansPricing[plan.id], plansAvailability[id])),
          pricing: toServicePricingModel(servicePricing),
          isOrderable: metadata.orderable.services[id],
          weight: SERVICE_WEIGHTS[id],
          isVisible: metadata.visibility.services[id],
        };
      }

      return {
        id,
        label,
        countries,
        plans: [],
        pricing: toServicePricingModel(servicePricing),
        isOrderable: metadata.orderable.services[id],
        weight: SERVICE_WEIGHTS[id],
        isVisible: metadata.visibility.services[id],
      };
    })
    .filter(({ isVisible }) => isVisible)
    .sort((a, b) => a.weight - b.weight);
}

function toCountryOptionsModel(
  countries: string[],
  availability?: CountryAvailabilityMetadataDTO,
): Array<Option<string, { availability: string }>> {
  return countries
    .map((code) => {
      const country = COUNTRIES.find((c) => c.value === code);

      if (!country) return null;

      return { ...country, parameters: { availability: availability?.[code] ?? OUT_OF_STOCK } };
    })
    .filter((v) => !isNil(v));
}

function toISPOptionsModel(
  isps: Record<string, Array<{ id: string; label: string }>>,
  availability?: ISPAvailabilityMetadataDTO,
): Record<string, Array<Option<string, { availability: string }>>> {
  return Object.entries(isps).reduce<Record<string, Array<Option<string, { availability: string }>>>>(
    (acc, [country, data]) => ({
      ...acc,
      [country]: data.map(({ id, label }) => ({
        value: id,
        label,
        parameters: { availability: availability?.[country][id] ?? OUT_OF_STOCK },
      })),
    }),

    {},
  );
}

function toPackageOptionsModel(
  from: Array<{ id: string; name: string }>,
  pricing?: Record<string, PackagePricingMetadataDTO>,
): Array<Option<string, OrderSetupPackagesParameters>> {
  if (!pricing) return [];

  return from.map(({ id }) => ({ label: id, value: id, parameters: { pricing: pricing[id] } }));
}

function toPeriodOptionsModel(
  periods: PartialRecord<Period, number[]>,
  availability?: PeriodAvailabilityMetadataDTO,
  pricing?: Record<Period, Record<string, PeriodPricingMetadataDTO>>,
): Record<Period, Array<Option<string, OrderSetupPeriodsParameters>>> {
  return Object.entries(periods).reduce<Record<Period, Array<Option<string, OrderSetupPeriodsParameters>>>>(
    (acc, [period, data]) => {
      if (period === 'days') {
        return {
          ...acc,
          [period as Period]: data.map((value) => ({
            value: `${value}d`,
            label: value === 7 ? 'common:trial.weekTrial' : 'common:form.day',
            parameters: {
              availability: availability?.[period as Period]?.[value.toString()],
              pricing: pricing?.[period as Period]?.[value.toString()],
            },
          })),
        };
      }

      return {
        ...acc,
        [period as Period]: data.map((value) => ({
          value: `${value}m`,
          label: 'common:form.month',
          parameters: {
            availability: availability?.[period as Period]?.[value.toString()],
            pricing: pricing?.[period as Period]?.[value.toString()],
          },
        })),
      };
    },
    { days: [], months: [] },
  );
}

function toQuantityOptionsModel(
  values: number[],
  pricing?: Record<string, QuantityPricingMetadataDTO>,
): Array<Option<number, OrderSetupQuantityParameters>> {
  if (!pricing) return [];

  return values.map((value) => ({ label: value.toString(), value, parameters: { pricing: pricing[value] } }));
}

// eslint-disable-next-line sonarjs/no-identical-functions
function toTrafficOptionsModel(
  values: number[],
  pricing?: Record<string, TrafficPricingMetadataDTO>,
): Array<Option<number, OrderSetupTrafficParameters>> {
  if (!pricing) return [];

  return values.map((value) => ({ label: value.toString(), value, parameters: { pricing: pricing[value] } }));
}

export function toOrderSetupModel({
  countries = [],
  isps = {},
  packages = [],
  periods = {},
  metadata,
}: OrderSetupWithMetadataDTO): OrderSetupModel {
  const { availability, visibility, pricing } = metadata;

  return {
    autoExtend: {
      isVisible: visibility.autoExtend,
    },
    autoExtendTraffic: {
      isVisible: visibility.autoExtendTraffic,
      availability: availability?.autoExtend?.traffic ?? null,
    },
    countries: {
      isVisible: visibility.countries,
      options: toCountryOptionsModel(countries, availability.countries),
    },
    isps: {
      isVisible: visibility.isps,
      options: toISPOptionsModel(isps, availability.isps),
    },
    packages: {
      isVisible: visibility.packages,
      options: toPackageOptionsModel(packages, pricing?.packages),
    },
    periods: {
      isVisible: visibility.periods,
      options: toPeriodOptionsModel(periods, availability.periods, pricing?.periods),
    },
    plans: {
      isVisible: visibility.plans,
    },
    quantity: {
      isVisible: visibility.quantity,
      availability: availability?.quantity ?? null,
      options: toQuantityOptionsModel(availability.quantity?.values ?? [], pricing?.quantity),
    },
    traffic: {
      isVisible: visibility.traffic,
      availability: availability?.traffic ?? null,
      options: toTrafficOptionsModel(availability.traffic?.values ?? [], pricing?.traffic),
    },
  };
}

function toSpecialOfferModel({
  discount,
  properties,
}: OrderSetupPriceSpecialOfferDTO): Nullable<OrderSetupPriceSpecialOfferModel> {
  if (properties.days) {
    return { property: 'days', discount, value: properties.days };
  }

  if (properties.months) {
    return { property: 'months', discount, value: properties.months };
  }

  if (properties.quantity) {
    return { property: 'quantity', discount, value: properties.quantity };
  }

  if (properties.traffic) {
    return { property: 'traffic', discount, value: properties.traffic };
  }

  return null;
}

function toPriceLabelsModel(
  appliedDiscounts: OrderSetupPriceAppliedDiscountsDTO[],
  labels: OrderSetupPriceLabelsDTO,
): OrderSetupPriceLabelModel[] {
  return appliedDiscounts
    .map(({ id, value }) => {
      const currentDiscount = labels.appliedDiscounts?.[id];

      if (!currentDiscount) return null;

      return { id, discount: value, accentColor: currentDiscount.accentColor, label: currentDiscount.label };
    })
    .filter((v) => !isNil(v));
}

export function toOrderSetupPriceModel({
  appliedDiscounts,
  currency,
  metadata,
  ...from
}: OrderSetupPriceWithMetadataDTO): OrderSetupPriceModel {
  if (Array.isArray(metadata)) return { ...from, currency: toCurrency(currency), specialOffer: null, labels: [] };

  return {
    ...from,
    currency: toCurrency(currency),
    specialOffer: !metadata.specialOffer ? null : toSpecialOfferModel(metadata.specialOffer),
    labels: !metadata.labels ? [] : toPriceLabelsModel(appliedDiscounts, metadata.labels),
  };
}

export function toOrderSetupExecuteModel(from: OrderSetupExecuteDTO): OrderSetupExecuteModel {
  return from;
}

// #endregion Mappers for DTOs

// #region Mappers for Payloads
export function buildServiceDetailsPayload({ service, testVariant, ...payload }: ServiceDetailsPayload) {
  if (!service.plans.length) return null;

  if ('planId' in payload) {
    return { planId: payload.planId, testVariant };
  }

  return { country: payload.country, testVariant };
}

export function toOrderSetupPayload(from: OrderSetupFormModel): OrderSetupPayload {
  const {
    serviceId,
    bandwidth,
    additionalBandwidth,
    autoExtendBandwidth,
    isAutoExtendEnabled,
    isp,
    package: proxyPackage,
    period,
    planId,
    country,
    quantity,
    couponCode,
    testVariant,
  } = from;

  if (serviceId === 'vpn') throw new Error('VPN is not supported');

  if (serviceId === 'rotating-mobile') {
    return {
      serviceId: 'rotating-mobile',
      traffic: bandwidth,
      couponCode,
      planId: null,
      autoExtend: { isEnabled: isAutoExtendEnabled, traffic: autoExtendBandwidth },
    };
  }

  if (serviceId === 'rotating-residential') {
    return {
      serviceId: 'rotating-residential',
      traffic: bandwidth,
      couponCode,
      planId: null,
      autoExtend: { isEnabled: isAutoExtendEnabled, traffic: autoExtendBandwidth },
    };
  }

  if (serviceId === 'static-datacenter-ipv4') {
    return {
      serviceId: 'static-datacenter-ipv4',
      couponCode,
      planId,
      quantity,
      country,
      testVariant,
      period: formatTimePeriod(period),
      autoExtend: { isEnabled: isAutoExtendEnabled, traffic: autoExtendBandwidth },
      ...(planId === 'basic' && { traffic: additionalBandwidth }),
    };
  }

  if (serviceId === 'static-datacenter-ipv6') {
    return {
      serviceId: 'static-datacenter-ipv6',
      couponCode,
      planId,
      country,
      testVariant,
      packageId: proxyPackage ?? '50',
      period: formatTimePeriod(period),
      autoExtend: { isEnabled: isAutoExtendEnabled, traffic: autoExtendBandwidth },
    };
  }

  return {
    serviceId: 'static-residential-ipv4',
    couponCode,
    planId,
    quantity,
    country,
    ispId: isp,
    testVariant,
    period: formatTimePeriod(period),
    autoExtend: { isEnabled: isAutoExtendEnabled, traffic: autoExtendBandwidth },
    ...(planId === 'basic' && { traffic: additionalBandwidth }),
  };
}

export function toOrderExecutePayload(
  from: OrderSetupFormModel,
  method?: PaymentOption,
  metadata?: Nullable<string>,
  transactionId = `T_${Date.now()}`,
): OrderExecutePayload {
  return {
    ...toOrderSetupPayload(from),
    transactionId,
    payment: { method, metadata: metadata ?? undefined },
  };
}
// #endregion Mappers for Payload
