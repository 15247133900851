export const isSSR = typeof window === 'undefined';

export function initialize(apiKey: string, onLoad: () => void) {
  if (!!apiKey && typeof window !== 'undefined' && typeof window.zE === 'undefined') {
    const appendScript = () => {
      setTimeout(() => {
        const s = document.createElement('script');
        s.id = 'ze-snippet';
        s.type = 'text/javascript';
        s.async = true;
        s.onload = onLoad;

        s.src = `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`;

        const x = document.getElementsByTagName('script')[0];
        x?.parentNode?.insertBefore(s, x);
      }, 0);
    };

    if (document.readyState === 'complete') {
      appendScript();
    } else if (window.attachEvent) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.attachEvent('onload', appendScript);
    } else {
      window.addEventListener('load', appendScript, false);
    }
  }
}
