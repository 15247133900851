import { combineReducers } from '@reduxjs/toolkit';

import { api } from './api';
import { authReducer } from './auth/reducers';
import { commonReducer } from './common/reducers';
import { orderReducer } from './orders/v1/reducers';
import { proxiesReducer } from './proxies/reducers';

export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  common: commonReducer,
  order: orderReducer,
  proxies: proxiesReducer,
});
