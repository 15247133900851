import { type MouseEvent, useCallback, useMemo } from 'react';

import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Icon } from 'components/Icon';
import { Logo } from 'components/Logo';
import { useHideModal } from 'components/modals/context';
import { RouterLink } from 'components/RouterLink';
import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useIsNewCustomer } from 'hooks/useIsNewCustomer';
import { useOrderTest } from 'hooks/useOrderTest';
import { useRouter } from 'hooks/useRouter';
import { useSession } from 'hooks/useSession';
import { useShowModal } from 'modules/common/GlobalModals';
import { useOrderFlow, useOrderState } from 'modules/orders/v2/hooks';
import { paths } from 'paths';
import { useGetProxiesISPsQuery } from 'store/api';
import { toNetworkType } from 'store/orders/v2/helpers';

import { OrderNavigationItem } from './OrderNavigationItem';
import { useOrderNavigationItems } from './useOrderNavigationItems';
import { getOrderSetupPostHogEventValues } from '../../helpers';

export function OrderNavigation() {
  const posthog = usePostHog();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { session } = useSession();
  const { isMarketplaceFlow } = useOrderFlow();
  const { variant } = useOrderTest();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const router = useRouter();
  const items = useOrderNavigationItems();
  const isNewCustomer = useIsNewCustomer();

  const hideModal = useHideModal();
  const showModal = useShowModal();

  const { data: isps = [] } = useGetProxiesISPsQuery();

  const couponCode = useOrderState((state) => state.couponCode);
  const serviceId = useOrderState((state) => state.selectedServiceId);
  const planId = useOrderState((state) => state.selectedPlanId);
  const price = useOrderState((state) => state.price);
  const selectedServiceId = useOrderState((state) => state.selectedServiceId);
  const selectedSetup = useOrderState((state) => state.selectedSetup);
  const setOrderStep = useOrderState((state) => state.setStep);
  const resetOrder = useOrderState((state) => state.resetOrder);

  const shouldDisplayBackArrow = useMemo(() => {
    if (!isMobile) return false;

    return pathname !== paths.order.v2.index;
  }, [isMobile, pathname]);

  const onConfirm = useCallback(() => {
    resetOrder();

    if (selectedServiceId) {
      GA_EVENTS.removeFromCart({
        userId: session?.userId,
        value: price?.finalPrice ?? 0,
        count: selectedSetup?.quantity ?? 1,
        coupon: couponCode ?? undefined,
        networkType: toNetworkType(selectedServiceId),
      });
    }

    posthog?.capture(
      posthogEvents.order.v2.exit,
      getOrderSetupPostHogEventValues({
        serviceId,
        planId,
        couponCode,
        userId: session?.userId,
        discount: price?.discount ?? 0,
        totalPrice: price?.finalPrice ?? 0,
        unitPrice: price?.unitPrice ?? 0,
        discountAmount: price?.discountAmount ?? 0,
        values: selectedSetup,
        isNewCustomer,
        isMarketplaceFlow,
        isps,
        variant,
        paymentType: null,
      }),
    );

    hideModal();

    return router.replace(isMarketplaceFlow ? paths.marketplace.index : paths.index);
  }, [
    couponCode,
    hideModal,
    isMarketplaceFlow,
    isNewCustomer,
    isps,
    planId,
    variant,
    posthog,
    price,
    resetOrder,
    router,
    selectedServiceId,
    selectedSetup,
    serviceId,
    session,
  ]);

  const onOrderExit = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      return showModal('orders.cancel', {
        title: t('order.v2.modals.cancel.title'),
        subtitle: t('order.v2.modals.cancel.subtitle'),
        onConfirm,
        confirmLabel: t('common:buttons.quit'),
      });
    },
    [onConfirm, showModal, t],
  );

  const onBackClick = useCallback(() => {
    return setOrderStep('setup');
  }, [setOrderStep]);

  return (
    <Stack
      component="header"
      bgcolor="var(--OrderNavigation-background)"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex="var(--OrderNavigation-zIndex)"
    >
      <Stack
        direction="row"
        flex="1 1 auto"
        justifyContent="space-between"
        alignItems="center"
        boxShadow="var(--mui-shadows-1)"
        minHeight="var(--OrderNavigation-height)"
        maxHeight="var(--OrderNavigation-height)"
        p={2}
        sx={(theme) => ({ transition: theme.transitions.create('all', { duration: 150 }) })}
      >
        <Fade in={shouldDisplayBackArrow} unmountOnExit>
          <IconButton onClick={onBackClick}>
            <Icon name="chevron-left" color="var(--mui-palette-neutral-400)" />
          </IconButton>
        </Fade>

        <Box
          display="inline-flex"
          component={RouterLink}
          href={paths.index}
          onClick={onOrderExit}
          zIndex="var(--OrderNavigation-logo-zIndex)"
        >
          <Logo wide={!isMobile} />
        </Box>

        <Fade in={!isMobile} unmountOnExit>
          <Stack
            direction="row"
            spacing={2}
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            justifyContent="center"
            zIndex="var(--OrderNavigation-tabs-zIndex)"
          >
            {items.map((item) => (
              <OrderNavigationItem key={item.title} {...item} />
            ))}
          </Stack>
        </Fade>

        <IconButton
          LinkComponent={RouterLink}
          href={paths.index}
          onClick={onOrderExit}
          sx={{ zIndex: 'var(--OrderNavigation-close-zIndex)' }}
        >
          <Icon name="cross" color="var(--mui-palette-neutral-400)" />
        </IconButton>
      </Stack>
    </Stack>
  );
}
