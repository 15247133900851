import { useCallback, useMemo, type ReactNode } from 'react';

import MuiAutocomplete, {
  type AutocompleteValue as MuiAutocompleteValue,
  type AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material/Autocomplete';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';

import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

import type { IconName } from 'components/Icon';
import type { LiteralNodeUnion, Option, Optional } from 'types';

import { InputOption } from './InputOption';

export type AutocompleteInputProps<T extends string, Parameters extends Optional<object>> = Omit<
  MuiAutocompleteProps<Option<T, Parameters>, false, true, true>,
  'error' | 'id' | 'name' | 'renderInput' | 'renderOption' | 'value' | 'disableClearable' | 'freeSolo' | 'multiple'
> & {
  /**
   * Input's name
   */
  name: string;

  /**
   * Input's label
   */
  label?: string;

  /**
   * Input's placeholder
   */
  placeholder?: string;

  /**
   * Input's value
   */
  value: T;

  /**
   * Determines if the input should display error
   */
  hasError?: boolean;

  /**
   * Input's error message
   */
  error?: string;

  /**
   * Input's helper text
   */
  helperText?: string;

  /**
   * Determines if the option's label and subLabel should be translated
   * Once enabled - the label and subLabel from options are being wrapped with `TFunction`
   *
   * @default true
   */
  shouldTranslateOptions?: boolean;

  /**
   * Helper function to render the option's label
   */
  getListItemLabel?: (option: string | Option<T, Parameters>, isSelected?: boolean) => ReactNode;

  /**
   * Helper function to render the option's subLabel
   */
  getListItemSubLabel?: (option: string | Option<T, Parameters>, isSelected?: boolean) => ReactNode;

  /**
   * Helper function to render the option's left adornment
   */
  getListItemLeftAdornment?: (
    option: string | Option<T, Parameters>,
    isSelected?: boolean,
  ) => LiteralNodeUnion<IconName>;

  /**
   * Helper function to render the option's right adornment
   */
  getListItemRightAdornment?: (
    option: string | Option<T, Parameters>,
    isSelected?: boolean,
  ) => LiteralNodeUnion<IconName>;
};

export function AutocompleteInput<T extends string, Parameters extends Optional<object>>({
  name,
  label,
  placeholder,

  fullWidth,
  disabled,

  options,
  value: valueProp,
  hasError,
  error,
  helperText,

  shouldTranslateOptions = true,

  getOptionLabel,

  getListItemLabel,
  getListItemSubLabel,
  getListItemLeftAdornment,
  getListItemRightAdornment,

  ...props
}: AutocompleteInputProps<T, Parameters>) {
  const { t } = useTranslation();

  const value = useMemo(() => {
    return options.find((o) => isEqual(o.value, valueProp)) ?? valueProp;
  }, [options, valueProp]);

  const renderOptionLabel = useCallback(
    (option: string | Option<T, Parameters>) => {
      if (getOptionLabel) {
        return getOptionLabel(option);
      }

      if (typeof option === 'string') {
        return shouldTranslateOptions ? t(option) : option;
      }

      return shouldTranslateOptions ? t(option.label) : option.label;
    },
    [getOptionLabel, shouldTranslateOptions, t],
  );

  const renderListItemLabel = useCallback(
    (option: string | Option<T, Parameters>, isSelected?: boolean) => {
      if (getListItemLabel) return getListItemLabel(option, isSelected);

      if (typeof option === 'string') return option;

      if (!shouldTranslateOptions) return option.label;

      return typeof option.value === 'number' ? t(option.label, { count: option.value }) : t(option.label);
    },
    [getListItemLabel, shouldTranslateOptions, t],
  );

  const renderListItemSubLabel = useCallback(
    (option: string | Option<T, Parameters>, isSelected?: boolean) => {
      if (getListItemSubLabel) return getListItemSubLabel(option, isSelected);

      if (typeof option === 'string') return null;

      if (!option.subLabel) return null;

      if (!shouldTranslateOptions) return option.subLabel;

      return t(option.subLabel);
    },
    [getListItemSubLabel, shouldTranslateOptions, t],
  );

  const isOptionEqualToValue = useCallback((a: string | Option<T, Parameters>, b: string | Option<T, Parameters>) => {
    if (typeof a === 'string' && typeof b === 'string') {
      return isEqual(a, b);
    }

    if (typeof a !== 'string' && typeof b !== 'string') {
      return isEqual(a.value, b.value);
    }

    if (typeof a === 'string' && typeof b !== 'string') {
      return isEqual(a, b.value);
    }

    if (typeof a !== 'string' && typeof b === 'string') {
      return isEqual(a.value, b);
    }

    return false;
  }, []);

  return (
    <FormControl error={hasError} fullWidth={fullWidth}>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}

      {/* @ts-expect-error - This is probably a valid assignment, but TypeScript messes things up */}
      <MuiAutocomplete
        {...props}
        id={name}
        value={value as MuiAutocompleteValue<Option<T, Parameters>, false, true, true>}
        disableClearable
        freeSolo
        autoSelect
        forcePopupIcon
        options={options}
        getOptionLabel={renderOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        renderInput={(params) => <TextField {...params} name={name} placeholder={placeholder} error={hasError} />}
        renderOption={({ key, ...liProps }, option, { selected }) => (
          <InputOption
            {...liProps}
            key={key}
            option={option}
            isSelected={selected}
            getItemLabel={renderListItemLabel}
            getItemSubLabel={renderListItemSubLabel}
            getItemLeftAdornment={getListItemLeftAdornment}
            getItemRightAdornment={getListItemRightAdornment}
          />
        )}
      />

      <Collapse in={hasError || !!helperText} unmountOnExit>
        <FormHelperText error={hasError}>{hasError ? error?.toString() : helperText}</FormHelperText>
      </Collapse>
    </FormControl>
  );
}
