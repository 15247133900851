import { useCallback, useMemo } from 'react';

import { useTheme } from '@mui/material/styles';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';

import { paths } from 'paths';

const titlesMap = {
  [paths.index]: 'overview',
  [paths.notFound]: 'notFound',

  [paths.auth.signIn]: 'auth.signIn',
  [paths.auth.mfa]: 'auth.mfa',
  [paths.auth.signUp]: 'auth.signUp',
  [paths.auth.forgotPassword]: 'auth.forgotPassword',

  [paths.order.index]: 'order.index',
  [paths.order.proxy]: 'order.proxy',
  [paths.order.details]: 'order.details',
  [paths.order.checkout]: 'order.checkout',
  [paths.order.vpn]: 'order.vpn',

  [paths.order.v2.index]: 'order.proxy',
  [paths.order.v2.details]: 'order.details',
  [paths.order.v2.checkout]: 'order.checkout',

  [paths.account.index]: 'account.index',
  [paths.account.general]: 'account.general',
  [paths.account.notifications]: 'account.notifications',
  [paths.account.security]: 'account.security',
  [paths.account.identityVerification]: 'account.identityVerification',
  [paths.account.payments]: 'account.payments',

  [paths.proxies.index]: 'proxies.index',
  [paths.proxies.active]: 'proxies.active',
  [paths.proxies.inactive]: 'proxies.inactive',
  [paths.proxies.expiring]: 'proxies.expiring',

  [paths.referrals.index]: 'referrals.index',

  [paths.quiz.index]: 'quiz.index',
};

export function Seo() {
  const { pathname, search } = useLocation();
  const { t } = useTranslation();
  const theme = useTheme();

  const pathToTitle = useCallback(() => {
    // Exceptions needs to be excluded from the map
    if (search.includes('preset=active') && matchPath(paths.proxies.index, pathname)) {
      return 'proxies.active';
    }

    if (search.includes('preset=inactive') && matchPath(paths.proxies.index, pathname)) {
      return 'proxies.inactive';
    }

    if (search.includes('preset=expiring') && matchPath(paths.proxies.index, pathname)) {
      return 'proxies.expiring';
    }

    if (matchPath(`${paths.proxies.index}/:proxyId`, pathname)) {
      return 'proxyOverview.index';
    }

    const title = titlesMap[pathname];

    return title;
  }, [pathname, search]);

  const fullTitle = useMemo(() => {
    const title = pathToTitle();

    // An array with exceptions - translations that needs to be taken from 'common' instead of 'headers
    const exceptions = ['auth.signUp', 'auth.signIn'];

    return title
      ? `${t(`${exceptions.includes(title) ? 'common' : 'headers'}:${title}`)} - Proxy-Cheap`
      : 'Proxy-Cheap';
  }, [pathToTitle, t]);

  pathToTitle();

  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="theme-color" content={theme.palette.background.paper} />
    </Helmet>
  );
}
