import type { OrderSetupValues } from 'modules/orders/v2/types';

export const DEFAULT_SETUP_VALUES: OrderSetupValues = {
  serviceId: 'static-residential-ipv4',
  planId: 'standard',
  country: null,
  isp: null,
  package: '50',
  period: '1m',
  quantity: 1,
  bandwidth: 5,
  isAutoExtendEnabled: true,
  autoExtendBandwidth: 10,
  additionalBandwidth: 1,
};
