import { useCallback, useEffect, useMemo, useState } from 'react';

import isEqual from 'lodash/isEqual';

import { useDebounce } from 'hooks/useDebounce';
import { useOrderTest } from 'hooks/useOrderTest';
import type { PaymentOption } from 'modules/billing/types';
import { useShowUnavailableDeals } from 'modules/orders/v1/hooks';
import type { OrderSetupValues } from 'modules/orders/v2/types';
import { useGetOrderServicePriceMutation } from 'store/api';
import { toOrderSetupPayload } from 'store/orders/v2/helpers';
import type { Nullable } from 'types';
import { getValidationErrors } from 'utils/error';
import { ToastManager } from 'utils/toast';

import { useOrderState } from './useOrderState';

function getDifference<T extends object>(prev: T, current: T) {
  const difference: Partial<T> = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const key in prev) {
    if (Object.prototype.hasOwnProperty.call(prev, key)) {
      if (!isEqual(prev[key], current[key])) {
        difference[key] = prev[key];
      }
    }
  }

  return Object.keys(difference);
}

type OrderSetupValueKey = keyof OrderSetupValues;

export function useOrderPrice(values: Nullable<OrderSetupValues>, paymentOption?: PaymentOption) {
  const [internalValues, setInternalValues] = useState(values);
  const debouncedValues = useDebounce(internalValues);

  const { variant } = useOrderTest();
  const couponCode = useOrderState((state) => state.couponCode);
  const setCouponCode = useOrderState((state) => state.setCouponCode);
  const setPrice = useOrderState((state) => state.setPrice);
  const setPriceLoading = useOrderState((state) => state.setPriceLoading);
  const showDealUnavailable = useShowUnavailableDeals();

  const [getOrderPrice, { isLoading, isUninitialized }] = useGetOrderServicePriceMutation();

  const getErrorMessage = useCallback((errors: Record<string, string | string[]>) => {
    return errors.couponCode.toString();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!debouncedValues || debouncedValues?.serviceId === 'vpn') return;

        const mappedValues = toOrderSetupPayload({
          ...debouncedValues,
          couponCode,
          ...(variant !== 'control' && { testVariant: variant }),
        });

        const response = await getOrderPrice({
          ...mappedValues,
          ...(paymentOption && { payment: { method: paymentOption, metadata: 'blank' } }),
        }).unwrap();

        setPrice(response);
      } catch (error) {
        showDealUnavailable(error);

        const validationErrors = getValidationErrors(error);

        if (validationErrors?.couponCode) {
          const errorMessage = getErrorMessage(validationErrors);

          if (errorMessage) {
            ToastManager.error(errorMessage);
          }

          return setCouponCode(null);
        }

        setPrice(null);
      }
    })();
  }, [
    couponCode,
    debouncedValues,
    getErrorMessage,
    getOrderPrice,
    paymentOption,
    variant,
    setCouponCode,
    setPrice,
    showDealUnavailable,
  ]);

  useEffect(() => {
    if (!values || !internalValues) return;

    if (isEqual(values, internalValues)) {
      return;
    }

    const differences = getDifference(values, internalValues);

    const excludedKeys: OrderSetupValueKey[] = ['country', 'isp', 'isAutoExtendEnabled', 'autoExtendBandwidth'];

    if (differences.every((item) => excludedKeys.includes(item as OrderSetupValueKey))) {
      return;
    }

    setInternalValues(values);
  }, [internalValues, values]);

  useEffect(() => {
    if (isUninitialized) return;

    setPriceLoading(isLoading);
  }, [isLoading, isUninitialized, setPrice, setPriceLoading]);

  return useMemo(() => ({ isLoading }), [isLoading]);
}
