import type { LanguageDTO } from 'store/accounts/dtos';
import type { Nullable } from 'types';

import type { MultiFactorHardwareOptions, MultiFactorProvider, UserRole } from './types';

/**
 * @POST /login-check
 */
export type AuthenticationDTO = TokenDTO & {
  mfa: Nullable<MultiFactorDTO>;
};

/**
 * @GET /captcha
 */
export type CaptchaDTO = {
  captchaEnabled: boolean;
  captchaSiteKey: Nullable<string>;
};

/**
 * @POST /login-check
 * @GET /2fa-status
 */
export type MultiFactorDTO = {
  providers: MultiFactorProvider[];
  preferredProvider: MultiFactorProvider;
  options: Nullable<MultiFactorHardwareOptions>;
};

/**
 * @GET /session
 */
export type SessionDTO = {
  userId: number;
  userIp: Nullable<string>;
  language: Nullable<LanguageDTO>;
  profileDetails: ProfileDetailsDTO;
  roles: UserRole[];
  trialCount: number;
  trialLimit: number;
  createdAt: string;
};

export type ProfileDetailsDTO = {
  email: string;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  isActive: boolean;
  valid: boolean;
  isSuspended: boolean;
  suspensionReason?: Nullable<string>;
  suspensionReasonExplanation?: Nullable<string>;
  verificationRequest: VerificationRequestDTO;
  isProfileSkipped: boolean;
};

export enum VerificationStatusDTO {
  NOT_VERIFIED = 'NOT_VERIFIED',
  VERIFICATION_PENDING = 'VERIFICATION_PENDING',
  VERIFICATION_REQUIRED = 'VERIFICATION_REQUIRED',
  VERIFICATION_SUCCESSFUL = 'VERIFICATION_SUCCESSFUL',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
}

export type VerificationRequestDTO = {
  status: VerificationStatusDTO;
  reason: Nullable<string>;
};

/**
 * @POST /login-check
 * @POST /registration
 */
export type TokenDTO = {
  token: string;
  refreshToken: string;
};
